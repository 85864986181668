import { Component, OnInit } from '@angular/core';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
declare var window: any;

@Component({
	selector: 'barcode-scanner',
	templateUrl: './barcode-scanner.component.html',
	styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent implements OnInit {

	results: any;
	constructor(private barcodeScanner: BarcodeScanner) { }

	ngOnInit() { }

	scanBarcode() {
		this.barcodeScanner.scan().then((barcodeData) => {
			// Success!  Barcode data is here...
			this.results = barcodeData;
		},
			(err) => {
				// An error occurred...
				alert(`Error with barcode scan: ${err}`);
			});
	}

	reset() {
		this.results = null;
	}

	lookupBarcode() {
		window.open(`http://www.upcindex.com/${this.results.text}`, '_system');
	}
}
