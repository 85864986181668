import { Component, OnInit, OnDestroy, ApplicationRef, ViewChild, ElementRef, Inject } from "@angular/core";
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';


import { Platform, ToastController, AlertController } from "@ionic/angular";
// import { Network } from "@ngx-pwa/offline";
import { SwUpdate, VersionEvent } from "@angular/service-worker";
import { Subscription, interval, concat, BehaviorSubject, Subject } from "rxjs";
import { filter, first } from "rxjs/operators";
import { SwPush } from "@angular/service-worker";

import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";

import { initializeLinq, IEnumerable, ArrayEnumerable } from "linq-to-typescript";
import { Plugins } from "@capacitor/core";
import { environment as Test } from "../../src/environments/environment.test";
import { environment as Beta } from "../../src/environments/environment.beta";
import { environment as Prod } from "../../src/environments/environment.prod";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { SecurityService } from "projects/shared-lib/src/lib/services/security.service";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { DashboardService } from "projects/shared-lib/src/lib/services/dashboard.service";
import { ServiceWorkerService } from "./services/service-worker.service";

import { IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService } from "ngx-ui-tour-md-menu";
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';



declare global {
	interface Array<T> extends IEnumerable<T> {
		concat(...items: Array<ReadonlyArray<T>>): ArrayEnumerable<T>;
		concat(...items: Array<T | ReadonlyArray<T>>): ArrayEnumerable<T>;
		concat(items: IEnumerable<T>): IEnumerable<T>;
	}
	interface Uint8Array extends IEnumerable<number> {}
	interface Uint8ClampedArray extends IEnumerable<number> {}
	interface Uint16Array extends IEnumerable<number> {}
	interface Uint32Array extends IEnumerable<number> {}
	interface Int8Array extends IEnumerable<number> {}
	interface Int16Array extends IEnumerable<number> {}
	interface Int32Array extends IEnumerable<number> {}
	interface Float32Array extends IEnumerable<number> {}
	interface Float64Array extends IEnumerable<number> {}
	interface Map<K, V> extends IEnumerable<[K, V]> {}
	interface Set<T> extends IEnumerable<T> {}
	interface String extends IEnumerable<string> {}
}

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
	@ViewChild("mainFooter") mainFooterHeight: ElementRef;

	public appVersion: string; // <-- Our application version.
	public copyright: Date = new Date();
	public subscriptions: Subscription[] = [];
	public events: Array<any>[];

	// public online$ = this.network.onlineChanges;
	public global: IGlobal = Global;

	public theme$: BehaviorSubject<string> = new BehaviorSubject<string>(Global.Theme);
	public companyLogo$: BehaviorSubject<any> = new BehaviorSubject<any>(Global.CompanyLogoImage);

	public componentName: string = "app.component.ts (mobile): ";
	public loading: boolean = false;
	public currentMenuItemTitle: string;
	private initialLoadOfAppComponent: boolean = false;
	public isAuthenticated: boolean = false;

	constructor(		private SplashScreen: SplashScreen,

		private platform: Platform,
				private swUpdate: SwUpdate,
				private toastr: ToastController,
				private alertify: AlertController,
				private applicationReference: ApplicationRef,
				private readonly swPush: SwPush,
				public dataService: DataService,
				private securityService: SecurityService,
				public utilityService: UtilityService,
				private router: Router,
				private dashboardService: DashboardService,
				private swService: ServiceWorkerService,
				public tourService: TourService,
				@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
				public authService: OktaAuthStateService)
	{

		this.initialLoadOfAppComponent = true;

		//console.log("window.screen = %O", window.screen);
		initializeLinq();
		// const evenNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9].where((x) => x % 2 === 0).toArray();
		// const evenNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9].where((x) => x % 2 === 0).toArray();
		// console.log("evenNumbers = %O", evenNumbers);
		// const test = [1, 2].concat([1, 2]);
		// console.log(test instanceof Array);
		// console.log("test = %O", test);
		// var str1 = new String( "This is string one" );
		// var str2 = new String( "This is string two" );
		// var str3 = str1.concat(str2.toString());
		// console.log("str1 + str2 : %O", str3);

		router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(async event => {
						console.log(event);
						this.isAuthenticated = await this.oktaAuth.isAuthenticated();
						if (this.isAuthenticated && !Global.User.isLoggedIn) {
							const userClaims = await this.oktaAuth.getUser();
							const authToken = this.oktaAuth.getAccessToken();
							console.log("user = %O", userClaims);
							console.log("Okta authToken = "+ authToken);
							this.securityService.processLoggedInUser(authToken, userClaims.email);
						}
		});

		Global.Initial = {
			href: window.location.href, //-- need full href to determine if we can travel to this route later after the user has been authenticated.
			route: window.location.href.indexOf("#") > -1 ? window.location.href.split("#").last() : null, //-- need the route so we can travel there after the user has been authenticated. --Kirk T. Sherer, November 17, 2023.
			baseUrl: window.location.href.indexOf("#") > -1 ? window.location.href.split("#").first() : window.location.href
		};

		if (document.URL.indexOf("localhost") > 0) {
			Global.Initial = {
				href: "https://test.iopspro.com/", //-- need full href to determine if we can travel to this route later after the user has been authenticated.
				route: window.location.href.indexOf("#") > -1 ? window.location.href.split("#").last() : null, //-- need the route so we can travel there after the user has been authenticated. --Kirk T. Sherer, November 17, 2023.
				baseUrl: "https://test.iopspro.com/"
			}
		}
		if (Global.iOPSLogoImage.indexOf("http") < 0) { //-- only set these images when we first come into the application.
			Global.LogosForApplicationTheme.Dark.iOPSLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Dark.iOPSLogo;
			Global.LogosForApplicationTheme.Dark.iOPSTinyLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Dark.iOPSTinyLogo;
			Global.LogosForApplicationTheme.Dark.CompanyTinyLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Dark.CompanyTinyLogo;
			Global.LogosForApplicationTheme.Dark.CompanyLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Dark.CompanyLogo;

			Global.LogosForApplicationTheme.Light.iOPSLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Light.iOPSLogo;
			Global.LogosForApplicationTheme.Light.iOPSTinyLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Light.iOPSTinyLogo;
			Global.LogosForApplicationTheme.Light.CompanyTinyLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Light.CompanyTinyLogo;
			Global.LogosForApplicationTheme.Light.CompanyLogo = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.LogosForApplicationTheme.Light.CompanyLogo;

			Global.iOPSLogoImage = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.iOPSLogoImage;
			Global.iOPSTinyLogoImage = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.iOPSTinyLogoImage;
			Global.CompanyTinyLogoImage = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.CompanyTinyLogoImage;
			Global.CompanyLogoImage = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.CompanyLogoImage;
			Global.imagesUrl = Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.imagesUrl;
			Global.Data.thingApiUrl = Global.Data.thingApiIsLocal == true ? "http://localhost:5044" : Global.Initial.baseUrl.replace("iopsmobile","iopspro") + Global.Data.thingApiUrl;
		}

		router.events.subscribe((routerEvent: Event) => {
			if (this.router.url != "/authentication" && this.router.url != "/user/authentication" && this.router.url != "/user/login" && this.router.url != "/user/logout" && this.router.url != "/" && this.router.url != "/layout/user-settings" && localStorage.getItem("currentUser")) {
				this.checkRouterEvent(routerEvent);
				if (Global?.User?.currentUser?.Id != null) {
					var lastVisitedRoute: string = localStorage.getItem("lastVisitedMobileRoute");
					var newlyVisitedRoute: string = this.router.url;
					localStorage.setItem("lastVisitedMobileRoute", this.router.url);
					localStorage.setItem("lastVisitedMobileRouteTitle", this.currentMenuItemTitle);

					if (lastVisitedRoute != newlyVisitedRoute) {
						//-- update SQL Server so we have the last route this user visited if we need to rebuild the local storage, but only if the routes are different.  No need to
						//-- continually update SQL Server with the same route. --Kirk T. Sherer, May 11, 2023.

						var sql: string = "Security.User_UpdateLastVisitedRoute @UserId=" + Global.User.currentUser.Id + ", @LastVisitedRoute='" + this.router.url + "', @LastVisitedRouteTitle='" + this.currentMenuItemTitle + "', @isMobile=1";
						this.dataService.SQLActionAsPromise(sql).then((data: any) => {
							console.log("Route logged. URL: " + this.router.url);
						});
					}
				}
			} else {
				this.loading = false;
			}
			// console.log("this.router.url: " + this.router.url + ", loading: " + this.loading);
		});

		this.utilityService.titleMenuChange$.subscribe((title: string) => {
			this.currentMenuItemTitle = title;
		});

		Global.Data.dataServerIsLocal = false; //--only set this to true if you're testing a local version of the webAPI.



		var environmentName = document.URL.indexOf("localhost") > 0 || document.URL.indexOf("test") > 0 ? (Global.Application?.Environment?.Name != null ? Global.Application.Environment.Name : "test") : document.URL.indexOf("beta") > 0 ? "beta" : "prod";
		var environmentId = null;
		var version = null;
		switch (environmentName) {
			case "test":
				environmentId = Test.environmentId;
				break;
			case "beta":
				environmentId = Beta.environmentId;
				break;
			case "prod":
				environmentId = Prod.environmentId;
				break;
		}

		Global.Application = {
			Environment: {
				Name: environmentName,
				Id: environmentId,
				Version: version
			},
			Type: "mobile"
		};

		console.log("app-component: Global = %O", Global);

	}

	checkRouterEvent(routerEvent: Event): void {
		if (routerEvent instanceof NavigationStart) {
			this.loading = true;
		} else {
			if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
				this.loading = false;
			}
		}
	}

	componentDidLoad() {
		console.log("SplashScreen should be visible: %O", this.SplashScreen);
	}

	async ngOnInit() {
		Global.isMobile = true;
		let interval = setInterval(() => {
			if (Global.User.isLoggedIn && !Global.User.isBeingAuthenticated) {
				clearInterval(interval);
			}
		}, 500);
		console.log(this.componentName + "SplashScreen: %O", this.SplashScreen);
		var passwordToken = this.utilityService.GetQuerystringParameterByName("pwt");
		if (passwordToken) {
			console.log(this.componentName + "passwordToken = " + passwordToken);
			Global.passwordToken = passwordToken;
			console.log(this.componentName + "Global.passwordToken = " + Global.passwordToken);
			this.router.navigate(["/forgot-password/" + passwordToken]);
		}

		this.SplashScreen.show();
		console.log(this.componentName + "SwUpdate.isEnabled = " + this.swUpdate.isEnabled);

		this.initializeVersionUpdater();

		//-- the next line is to figure out if the user has their overall mobile device set to a dark theme.  If so, then we're forcing the dark theme on this application. --Kirk T. Sherer, February 12, 2021.
		Global.User.prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

		console.log(this.componentName + "Global.User.prefersDarkTheme = " + Global.User.prefersDarkTheme);

		this.subscriptions.push(
			this.theme$.subscribe((theme: string) => {
				console.log("app.component.ts (mobile): theme changed to " + theme);
			}),
			this.dataService.companyLogo$.subscribe((image: any) => {
				console.log(this.componentName + "JBT Logo has changed. Switching to " + image + "...");
				this.companyLogo$.next(image);
				this.theme$.next(Global.Theme);
			}),
			this.dataService.themeChanged$.subscribe((theme: string) => {
				console.log(this.componentName + "Theme has changed. Switching to " + theme + " theme.");
				this.theme$.next(theme);
				this.companyLogo$.next(Global.CompanyLogoImage);
			})
		);

		//-- This 'prefersDarkTheme' check is required if the user has set their overall mobile device to a dark mode theme.  If that happens, we MUST force the iOPS
		//-- application to be loaded in a dark mode them and we also are removing the ability to change the iOPS Dark/Light mode theme in the User Settings
		//-- because the application can't override the mobile device's dark mode setting.  They have a message on the Dark/Light mode setting field
		//-- that tells them they must change their overall mobile device back to a normal theme and only then can you change the iOPS theme from dark to light.
		//------- Kirk T. Sherer, August 12, 2022.

		if (Global.User.prefersDarkTheme) {
			const body = document.getElementsByTagName("body")[0];
			body.style.background = "black";
			this.dataService.setTheme("dark"); //--set the theme to dark mode since the user has set their overall dark mode setting on their phone. --Kirk T. Sherer, February 11, 2021.
		} else {
			this.dataService.setTheme(Global.DefaultThemeForApplication);
		}



		var environmentName = document.URL.indexOf("localhost") > 0 || document.URL.indexOf("test") > 0 ? Global.Application.Environment.Name : document.URL.indexOf("beta") > 0 ? "beta" : "prod";
		var environmentId = null;
		var version = null;
		switch (environmentName) {
			case "test":
				environmentId = Test.environmentId;
				break;
			case "beta":
				environmentId = Beta.environmentId;
				break;
			case "prod":
				environmentId = Prod.environmentId;
				break;
		}

		Global.Application = {
			Environment: {
				Name: environmentName,
				Id: environmentId,
				Version: version
			},
			Type: "mobile"
		};

		console.log(this.componentName + "Global.Theme = " + Global.Theme);
		console.log(this.componentName + "Global = %O", Global);

		console.log("this.initialLoadOfAppComponent = " + this.initialLoadOfAppComponent);

		//-- only run this code after we've initially logged in the user from the constructor. --Kirk T. Sherer, December 16, 2024.
		// this.isAuthenticated = await this.oktaAuth.isAuthenticated();
		// if (this.isAuthenticated == true) {
		// 	const userClaims = await this.oktaAuth.getUser();
		// 	const authToken = this.oktaAuth.getAccessToken();
		// 	console.log("user = %O", userClaims);
		// 	console.log("Okta authToken = "+ authToken);
		// 	this.securityService.processLoggedInUser(authToken, userClaims.email);
		// }
		// else {
		// 	this.oktaAuth.signInWithRedirect();
		// }

	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

	initializeVersionUpdater() {
		const updateInterval$ = interval(1000 * 60 * 1); //-- Check for updates every minute...
		const appIsStable$ = this.applicationReference.isStable.pipe(first((isStable) => isStable === true));
		const appIsStableInterval$ = concat(appIsStable$, updateInterval$);

		this.subscriptions.push(
			this.swUpdate.versionUpdates.subscribe((e) => {
				console.log("iOPS Software Update available -> event type: " + e.type);
				this.onUpdateAvailable(e);
			})
		);
		// this.subscriptions.push(
		// 	this.swUpdate.activateUpdate. .subscribe((e) => {
		// 		// console.log("iOPS Software Update activated -> old version: %O", e.previous);
		// 		// console.log("iOPS Software Update activated -> new version: %O", e.current);
		// 		this.onUpdateActivated(e);
		// 	})
		// );
		this.subscriptions.push(
			appIsStableInterval$.subscribe(() => {
				// console.log("app.component.ts: application is considered 'stable'.");
				this.checkForApplicationUpdate();
				this.initializeApp();
			})
		);
	}

	initializeApp() {
		this.platform.ready().then(() => {
			//console.log("about to hide SplashScreen: %O", SplashScreen);
			this.SplashScreen.hide();
			this.swPush.notificationClicks.subscribe((event) => {
				console.log(this.componentName + "Received notification: ", event);
				const url = event.notification.data.url;
				window.open(url, "_blank");
			});
		});

	}

	async checkForApplicationUpdate() {
		//console.log("app.component.ts: software update enabled = " + this.swUpdate.isEnabled);

		if (this.swUpdate.isEnabled) {
			//console.log("app.component.ts: Checking for software update...");
			await this.swUpdate.checkForUpdate();
		}
		// else {
		// 	this.alertifyMessage("Update Cancelled", "Software Update is NOT enabled.");
		// }
	}
	// async onUpdateActivated(event: UpdateActivatedEvent) {
	// 	await this.updateApplication(event);
	// }

	async onUpdateAvailable(event: VersionEvent) {
		this.utilityService.showToastMessageShared({
			type: "success",
			message: "New iOPS Application version detected..."
		});
		await this.updateApplication(event);
	}

	async alertifyMessage(header: string, message: string) {
		const alert = await this.alertify.create({
			header: header,
			message: `<strong>Message</strong>: ${message}`,
			buttons: [
				{
					text: "OK",
					handler: null
				}
			]
		});

		await alert.present();
	}

	async updateApplication(event: any) {
		var updateMessage = "";

		if (event.type == "VERSION_READY" || event.type == "VERSION_DETECTED") {
			console.log("A new version of iOPS is ready to be installed: ");
			updateMessage = event.latestVersion.appData["updateMessage"];
			const alert = await this.alertify.create({
				header: "iOPS Update Available",
				message: "A new vesion of the iOPS Application is available and will now be installed." + `<br /><br /><strong>Details:</strong> ${updateMessage}`,
				buttons: [
					{
						text: "OK",
						handler: async () => {
							this.utilityService.showToastMessageShared({
								type: "info",
								message: "iOPS Application updating..."
							});
							await this.swUpdate.activateUpdate().then(() => {
								window.location.reload();
							});
						}
					}
				]
			});

			await alert.present();
		}
	}

	public goBack() {
		//-- first, get the route from the Global.Dialog object.  We will be routing to this location. --Kirk T. Sherer, October 29, 2020.
		var route = Global.Dialog.lastRouteURL.substr(1, Global.Dialog.lastRouteURL.length - 1);
		this.clearBackButton();
		this.router.navigate([route]); //-- go to the actual route. --Kirk T. Sherer, October 29, 2020.
	}

	public clearBackButton() {
		//-- clear out Dialog since we shouldn't retain the last dialog used. This also removes the Back button from the list of tabs. --Kirk T. Sherer, October 29, 2020.
		Global.Dialog = {
			lastRouteURL: "",
			currentDialogObject: null,
			componentName: ""
		};
	}

	public setBackButton() {
		Global.Dialog.lastRouteURL = this.router.url;
		console.log("this.router.url = " + this.router.url);
	}
}
