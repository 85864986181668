import { Routes } from '@angular/router';
import { GeolocationComponent } from '../native/geolocation/geolocation.component';
import { CameraComponent } from '../native/camera/camera.component';
import { VibrationComponent } from '../native/vibration/vibration.component';
import { BarcodeScannerComponent } from '../native/barcode-scanner/barcode-scanner.component';
import { DeviceMotionComponent } from '../native/device-motion/device-motion.component';
import { AuthGuard } from '../user/authentication/authorization.guard';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { HomePage } from '../home/home.page';
import { HomeComponent } from 'projects/desktop/src/app/home/home.component';


export const detailedRoutes: Routes = [
	{
		path: 'login/callback',
		component: OktaCallbackComponent,
	},
	{
		path: "home",
		component: HomePage,
		loadChildren: () =>
			import('../home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'folder/Vibration',
		canActivate: [OktaAuthGuard],
		component: VibrationComponent,
	},
	{
		path: 'folder/Geolocation',
		canActivate: [OktaAuthGuard],
		component: GeolocationComponent,
	},
	{
		path: 'folder/Camera',
		canActivate: [OktaAuthGuard],
		component: CameraComponent,
	},
	{
		path: 'folder/BarcodeScanner',
		canActivate: [OktaAuthGuard],
		component: BarcodeScannerComponent,
	},

	{
		path: 'folder/DeviceMotion',
		canActivate: [OktaAuthGuard],
		component: DeviceMotionComponent,
	},

	// {
	// 	path: 'folder/:id',
	// 	canActivate: [OktaAuthGuard],
	// 	loadChildren: () =>
	// 		import('../folder/folder.module').then((m) => m.FolderPageModule),
	// },
	{
		path: 'authentication',
		loadChildren: () =>
			import('../user/authentication/authentication.module').then(
				(m) => m.AuthenticationPageModule
			),
	},
	{
		path: 'login',
		component: HomePage,
		loadChildren: () =>
			import('../home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'forgot-password/:pwt',
		loadChildren: () =>
			import('../user/forgot-password/forgot-password.module').then(
				(m) => m.ForgotPasswordPageModule
			),
	},
	{
		path: 'layout',
		canActivate: [OktaAuthGuard],
		loadChildren: () =>
			import('../_layout/layout.module').then((m) => m.LayoutPageModule),
	},

	{
		path: 'logout',
		loadChildren: () =>
			import('../user/logout/logout.module').then(
				(m) => m.LogoutPageModule
			),
	},
	{
		path: 'registration',
		loadChildren: () =>
			import('../user/registration/registration.module').then(
				(m) => m.RegistrationPageModule
			),
	},
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: 'login',
		pathMatch: 'full',
	},
];
