import 'zone.js'
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));

platformBrowserDynamic().bootstrapModule(AppModule).then((data:any) => {
  if ('serviceWorker' in navigator ) {
    navigator.serviceWorker.register('ngsw-worker.js');
  }
}).catch((err:Error) => { 
  alert(`main.ts: Error in platformBrowserDynamic.bootstrapModule: ${err}`);
  console.error(`main.ts: Error in platformBrowserDynamic.bootstrapModule: ${err}`);
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);