import { Component, OnInit } from '@angular/core';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

@Component({
  selector: 'geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss'],
})
export class GeolocationComponent implements OnInit {

  location: { lat: number, lng: number }

  constructor(private geolocation: Geolocation) { }

  ngOnInit() { }


  getGeolocation() {
    console.log("getting geolocation...");
    this.geolocation.getCurrentPosition().then((response) => {
      this.location = {
        lat: response.coords.latitude,
        lng: response.coords.longitude
      }
    })
  }
}
