import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { detailedRoutes } from "./_constants/detailed.routes";

@NgModule({
	imports: [
		RouterModule.forRoot(detailedRoutes, {
			preloadingStrategy: PreloadAllModules,
			useHash: false,
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled",
			scrollOffset: [0, 64],
			enableTracing: false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
