import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { IGlobal } from '../../_models/global.model';




@Component({
	selector: 'lib-loading-spinner',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, AfterViewInit {
	public global: IGlobal = Global;
	@Input() vehicle;
	@Input() loadingMessage;
	fullDataCacheSubscription: any;
	theme: any;
	colorChangedSubscription: any;
	public theme$: BehaviorSubject<string> = new BehaviorSubject<string>(Global.Theme);
	public companyLogo$: BehaviorSubject<any> = new BehaviorSubject<any>(Global.CompanyLogoImage);
	private checkUserLogin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(Global.User.isLoggedIn);
	public isLoggedIn: boolean;
	private componentName: string = "loading-spinner: ";


	constructor(public dataService: DataService) { }

	ngOnInit(): void {
		console.log("Global.Theme = " + Global.Theme);
	}
	ngAfterViewInit(): void {
		console.log(this.componentName + "Global = %O", Global);
		console.log(this.componentName + "Global.Theme = " + Global.Theme);
		this.checkUserLogin$ = new BehaviorSubject(Global.User.isLoggedIn);
		this.checkUserLogin$.subscribe(
			(isLoggedIn: boolean) => {
				this.isLoggedIn = isLoggedIn;
			});
	}

	returnThemeColor(): any {
		return Global.User.MenuColor;
	}

	returnSpinnerClass(){
		return 'dylan'
	}

	returnSpinnerColor(){
		switch (Global.User.MenuColor) {
			case "dark-gray":
			  return "#696868";
			  break;
			case "dark-red":
			  return "#7d0f0f";
			  break;
			case "dark-blue":
			  return "#A5B6F3";
			  break;
			case "dark-green":
			  return "#075013";
			  break;
			case "light-gray":
			  return "#A4A3A3";
			  break;
			case "light-red":
			  return "#FA5C5C";
			  break;
			  case "fire-red":
				  return "#Ce0B15"
				  break;
			case "light-blue":
			  return "#A5B6F3";
			  break;
			case "tan":
			  return "#D2B48C";
			  break;

			case "yellow-gold":
			  return "#EDC025";
			  break;
			case "bright-green":
			  return "#C0F10D";
			  break;
		  }
	}
}
