import { Component, OnInit } from '@angular/core';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

@Component({
	selector: 'camera',
	templateUrl: './camera.component.html',
	styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {

	imageSource: any;

	constructor(private camera: Camera) { }

	ngOnInit() { }

	takePicture() {
		console.log("taking picture with mobile device...");
		let options: CameraOptions = {
			destinationType: this.camera.DestinationType.FILE_URI,
			sourceType: this.camera.PictureSourceType.CAMERA,
			encodingType: this.camera.EncodingType.PNG,
			targetHeight: 500,
			targetWidth: 500,
			saveToPhotoAlbum: false
		}
		console.log("camera options: %O", options);

		this.camera.getPicture(options).then((imageUri) => {
			console.log("imageUri = %O", imageUri);
			this.imageSource = imageUri;
		});
	}
}
