import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NativeModule } from '../app/native/native.module';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { DeviceMotion } from '@awesome-cordova-plugins/device-motion/ngx';
import { ServicesModule } from 'projects/shared-lib/src/lib/services/services.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { DirectivesModule } from './_other-modules/directives.module';

import { Plugins } from '@capacitor/core';


import { ReleaseNotesModule } from 'projects/shared-lib/src/lib/components/release-notes/release-notes.module';
import { PipeModule } from 'projects/shared-lib/src/lib/_helpers/pipes/pipe.module';
import { LoadingSpinnerModule } from 'projects/shared-lib/src/lib/components/loading-spinner/loading-spinner.module';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarBroadcastMessageModule } from 'projects/shared-lib/src/lib/components/snackbar-broadcast-message/snackbar-broadcast-message.module';
import { DialogModule } from '@progress/kendo-angular-dialog';

// import { AssetSummaryViewModule } from 'projects/shared-lib/src/lib/components/asset-summary-view/asset-summary-view.module';
import {

	TourMatMenuModule,
	TourService,
} from 'ngx-ui-tour-md-menu';
import { MaterialModule } from 'projects/desktop/src/app/_other-modules/material.module';
import {
	OKTA_CONFIG,
	OktaAuthGuard,
	OktaAuthModule,
	OktaCallbackComponent,
  } from '@okta/okta-angular';
  import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
	issuer: 'https://osk.oktapreview.com/oauth2/default',
	clientId: '0oabz32fcyLIjrgsG1d7',
	redirectUri: window.location.origin + '/login/callback',
	scopes: ['openid', 'profile', 'offline_access', 'email']
  });
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

@NgModule({
	declarations: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
	imports: [IonicModule.forRoot(),
		DirectivesModule,
		BrowserModule,
		BrowserAnimationsModule,
		ServicesModule,
	ToastrModule.forRoot({
		timeOut: 5000,
		positionClass: 'toast-bottom-left',
		preventDuplicates: true,
	}),
		AppRoutingModule,
		NativeModule,
		PipeModule,
	ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
		ReleaseNotesModule,
		LoadingSpinnerModule,
		SnackbarBroadcastMessageModule,
		DialogModule,
		MaterialModule,
		TourMatMenuModule,
		OktaAuthModule.forRoot({ oktaAuth })
	],
	providers: [
		SplashScreen,
		NativeModule,
		StatusBar,
		Vibration,
		Geolocation,
		File,
		BarcodeScanner,
		DeviceMotion,

		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: MatDialogRef,
			useValue: {},
		},
		TourService,
		provideHttpClient(withInterceptorsFromDi()),

	],
})
export class AppModule { }

// -- ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }) will only have the service worker module enabled for production.  I set the enabled: true above so that
// -- all versions (Test, Beta, and Prod) would be enabled for Service Worker updates. --Kirk T. Sherer, January 27, 2021.
