import { Component, Inject, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import $ from 'jquery';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { SecurityService } from "projects/shared-lib/src/lib/services/security.service";


@Component({
	selector: "app-home",
	templateUrl: "./home.page.html",
	styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit {
	private isAuthenticated: boolean = false;
	private userName: string; 

	constructor(private router: Router,	@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, public securityService: SecurityService) {}

	async ngOnInit() {
		console.log("home page invoked...");
		console.log("HomePage: private router: Router injected...");

		this.isAuthenticated = await this.oktaAuth.isAuthenticated();
		if (!this.isAuthenticated) {
			this.oktaAuth.signInWithRedirect();
		}

		if (Global?.User?.currentUser?.Dashboards != undefined) {
			this.router.navigate(["layout/dashboard/" + Global.User.currentUser.Dashboards.firstOrDefault().Id]);
		} else {
			this.router.navigate(["layout/dashboard-demo"]); // -- go to demo dashboard since user doesn't have any dashboards yet. --Kirk T. Sherer, February 4, 2020.
		}
	}
}
