
<div class="centerDisplaySpinner" *ngIf="(!global.User.loggingIntoLegacyApplication && !global.User.isRequestingAccount) || !isLoggedIn" >
	<img [src]="companyLogo$ | async" style="height: 212px;" *ngIf="dataService.applicationLoadingMessageObject.shouldExist && global.isMobile === false">
	<img [src]="companyLogo$ | async" *ngIf="dataService.applicationLoadingMessageObject.shouldExist && global.isMobile === true">
	<div style="justify-content: center; align-items: center; display: flex; padding-top: 20px;">
        <span class="{{ theme$ | async }}-theme loader-text" class="loader-text" style="text-align: center;" [style.color]="global.Theme === 'light' ? '#212427' : '#ffffff'">{{loadingMessage}}</span>
    </div>
	<br/>
	<div style="align-content: center; justify-content: center; display: flex;">
		<kendo-loader [style.color]="returnSpinnerColor()" *ngIf="global.Theme === 'light'" [type]="'converging-spinner'" [themeColor]="'secondary'" [size]="'medium'"></kendo-loader>
		<kendo-loader *ngIf="global.Theme === 'dark'" [type]="'converging-spinner'" [themeColor]="'tertiary'" [size]="'medium'"></kendo-loader>
	</div>
</div>
