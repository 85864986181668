import { Injectable } from '@angular/core';
import $ from 'jquery';


@Injectable()
export class DisplaySetupService {

    constructor() {

    }

    setPanelBodyWithIdHeight(id: number, additionalHeightPixels?: number) {
        //console.log("zzId = " + id);
        if (!additionalHeightPixels) {
            additionalHeightPixels = 0;
        }
        setTimeout(() => {

            $("#" + id).each(function (index, element) {

                //console.log("zz Element = %O", element);

                //Convert the childnodes from nodelist to array
                var cardNode: any = $(element)[0].parentNode;
                //console.log("zz display-setup.service: cardNode = %O", cardNode);
                var elementParentChildNodesArray = Array.prototype.slice.call(cardNode.childNodes);
                //console.log("zz display-setup.service: elementParentChildNodesArray = %O", elementParentChildNodesArray);
                var cardHeaderNode = elementParentChildNodesArray.first((cn:any) => {return cn.id == ("card-header-" + id)});
                var cardBodyNode = elementParentChildNodesArray.first((cn:any) => {return cn.id == (id)});
                //console.log("zz display-setup.service: cardHeaderNode = " + cardHeaderNode);
                //console.log("zz display-setup.service: elementParentChildNodesArray = %O", elementParentChildNodesArray);


                var cardHeaderHeight = cardHeaderNode.offsetHeight;
                var cardBodyHeight = cardBodyNode.offsetHeight;
                //console.log("zz CardHeaderHeight = " + cardHeaderHeight);
                //console.log("zz CardBodyHeight = " + cardBodyHeight);

                //debugger;
                $(element).css('height', cardNode.offsetHeight - cardHeaderHeight - additionalHeightPixels);
                $(element).css('offsetHeight', cardNode.offsetHeight - cardHeaderHeight - additionalHeightPixels);

            });
        }, 25);
    }

    setWidgetPanelBodyDimensions(widgetId: number) {
        //console.log("zz widgetId = " + widgetId);
        var widgetBody = $("#" + widgetId);
        //console.log("zz widgetBody: %O", widgetBody);
        if (widgetBody[0]) {
            var cardElement = widgetBody[0].parentElement;
            //console.log("zz display-setup.service: cardElement = %O", cardElement);

            //Find the card header so we can determine its height
            var cardHeaderElement = $(cardElement).find(".card-header")[0];
            //console.log("zz display-setup.service: cardHeaderElement = %O", cardHeaderElement);
            //console.log("zz display-setup.service: Card Header Element Height = " + cardHeaderElement.offsetHeight);

            var cardWidth = cardElement.offsetWidth;
            //console.log("zz display-setup.service: cardWidth = " + cardWidth);
            var widgetContentHeight = cardElement.offsetHeight - cardHeaderElement.offsetHeight;
            //console.log("zz display-setup.service: widgetContentHeight = " + widgetContentHeight);
            widgetBody.css('height', widgetContentHeight - 2);
            $("." + widgetId + "-repeater-container").each(function (index, element) {
                $(element).css('height', +widgetContentHeight - 33);
                $(element).css('width', +cardWidth - 17);
                $(element).css('overflow-y', "hidden");
            });
            return { width: cardWidth, height: widgetContentHeight };

        }
    }

    setWidgetIFrameHeight = function (widgetId) {
        var widgetDimensions: any = this.getWidgetPanelBodyDimensions(widgetId);
        if (widgetDimensions) {
            $("#systemIFrame" + widgetId).css('height', widgetDimensions.height);
        }
    }

    getWidgetPanelBodyDimensions(widgetId: number) {
        var widgetPanelBody: any = $("#" + widgetId);
        if (widgetPanelBody[0]) {
            var panelElement: any  = widgetPanelBody[0].parentElement;
            //Find the panel heading so we can determine its height
            var panelHeadingElement: any  = $(panelElement).find(".panel-heading")[0];
            var panelHeadingHeight = panelHeadingElement.offsetHeight;
            var panelWidth: any  = panelElement.offsetWidth;
            var widgetContentHeight: any  = panelElement.offsetHeight - panelHeadingElement.offsetHeight;
            return { width: panelWidth, height: widgetContentHeight };

        }
    }

}
