<ion-card>
	<ion-card-header>
	  <ion-card-title>Barcode Scanner</ion-card-title>
	</ion-card-header>
	<ion-card-content>
	  <button full primary (click)="scanBarcode()">Scan Barcode</button>
	  <button full secondary (click)="reset()">Reset</button>
	  <div *ngIf="results">
		<h2>Format: {{results.format}}</h2>
		<h2>Text: {{results.text}}</h2>

		<button full primary (click)="lookupBarcode()">Lookup Barcode</button>
	  </div>
	</ion-card-content>
  </ion-card>
